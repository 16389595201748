import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import CreateTranscription from "./components/CreateTranscription";
import ListTranscriptions from "./components/ListTranscriptions";
import Transcription from "./components/Transcription";
import Layout from "./components/Layout";
import { ToastContainer } from "react-toastify";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import PrivateRoute from "./components/PrivateRoute";
import { useAuth } from "react-oidc-context";

function App() {
  const auth = useAuth()

  if (auth.isLoading) return null

  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen">
        <Nav />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/create-transcription"
              element={
                <PrivateRoute>
                  <Layout>
                    <CreateTranscription />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/list-transcription"
              element={
                <PrivateRoute>
                  <Layout>
                    <ListTranscriptions />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/transcriptions/:id"
              element={
                <PrivateRoute>
                  <Layout>
                    <Transcription />
                  </Layout>
                </PrivateRoute>
              }
            />
          </Routes>

          <ToastContainer />
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;

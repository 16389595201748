import { getBlobSas } from "./services/Sas";
import { firstValueFrom } from 'rxjs';
/**
 * Retrieves the Shared Access Signature (SAS) for an Azure Blob.
 * @param blobName - The name of the blob.
 * @param container - The name of the container. Defaults to "audio" if not provided.
 * @returns A Promise that resolves to the SAS string.
 */
export const getAzureBlobSas = async (
    blobName: string,
    container: string
  ): Promise<string> => {
    const getBlobSasArgs = {
      blob: blobName,
      container: container ? container : 'audio',
    };
  
    const res = await firstValueFrom(getBlobSas(getBlobSasArgs));
    const data = res?.response as any;
    return data.sas;
  };
  

import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { GetBlobSasArgs } from "../models/GetBlobSasArgs";
import { LoginTranscriptionCount } from "../models/LoginTranscriptionCount";
import { baseURL, createHeaders } from "./ServiceUtil";


export const getBlobSas = (getBlobSasArgs: GetBlobSasArgs) =>
  ajax.post(`${baseURL}/sas_blob/`, getBlobSasArgs, createHeaders());

export const getAccountSas = () =>
  ajax.post(`${baseURL}/sas_account/`, {}, createHeaders());

export const getLoginCount = (): Observable<LoginTranscriptionCount> =>
  ajax.getJSON<LoginTranscriptionCount>(`${baseURL}/login-transcription-count/`, createHeaders());

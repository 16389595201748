import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { PostTranscriptionForm } from "../models/PostTranscriptionForm";
import { Transcriptions } from "../models/GetTranscription";
import { Transcription } from "../models/Transcription";
import { TranscriptionData } from "../models/TranscriptionData";
import { baseURL, createHeaders } from "./ServiceUtil";

export const postTranscription = (transcriptionPayload: PostTranscriptionForm) =>
  ajax.post(`${baseURL}/transcriptions/`, transcriptionPayload, createHeaders());

export const getTranscriptionList = (): Observable<Transcriptions> =>
  ajax.getJSON<Transcriptions>(`${baseURL}/transcriptions/`, createHeaders());

export const getTranscriptionResults = (id: string | undefined): Observable<Transcription> =>
  ajax.getJSON<Transcription>(`${baseURL}/transcriptions/${id}/`);

export const getTranscription = (contentUrl: string): Observable<TranscriptionData> =>
  ajax.getJSON<TranscriptionData>(contentUrl);

export const deleteTranscription = (id: string | undefined) =>
  ajax.delete(`${baseURL}/transcriptions/${id}/`, createHeaders());


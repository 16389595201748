import { useContext, useEffect, useState } from "react";
import { Data, Transcriptions } from "../models/GetTranscription";
import { useNavigate } from "react-router-dom";
import { TranscriptionContext } from "../contexts/TranscriptionContext";
import { useAuth } from "react-oidc-context";
import Alert from "./Alert";
import LoadingSpinner from "./LoadingSpinner";
import { getTranscriptionList, deleteTranscription } from "../services/Transcription";


// Lists all the transcriptions that user has access to
const ListTranscriptions = () => {
  // Access the current transcriptions from the context
  const auth = useAuth();
  const currentTranscriptions = useContext(TranscriptionContext);
  const [transcriptions, setTranscriptions] = useState<Transcriptions | null>(
    currentTranscriptions
  );
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setTranscriptions(currentTranscriptions);
  }, [currentTranscriptions]);

  // Function to load the transcription list
  const loadTranscriptionList = () => {
    setIsLoading(true);
    const getTranscription$ = getTranscriptionList();
    const sub = getTranscription$.subscribe({
      next: (res) => {
        setTranscriptions(res);
        setIsLoading(false);
      },
      complete: () => {
        setIsLoading(false)
      }
    });
    return () => sub.unsubscribe();
  };


  // Use effect hook to load the transcription list when the authentication status changes
  useEffect(() => {
    auth.isAuthenticated && loadTranscriptionList();
  }, [auth.isAuthenticated]);


  // Function to handle deletion of a transcription
  const onDeleteTranscription = (transcription: Data) => {
    setIsLoading(true);
    const parts = transcription.self.split("/");
    const transcriptionId = parts.pop();
    const deleteTranscription$ = deleteTranscription(transcriptionId);
    const sub = deleteTranscription$.subscribe({
      next: () => {
        setIsLoading(false);
        loadTranscriptionList()
      },
      complete: () => {
        setIsLoading(false);
      }
    });
    return () => sub.unsubscribe();
  };


  // Function to open a specific transcription
  const openTranscription = (transcription: Data) => {
    const parts = transcription.self.split("/");
    const transcriptionId = parts.pop();
    navigate("/transcriptions/" + transcriptionId);
  };


  // If the user is not authenticated, render nothing
  if (!auth.isAuthenticated) {
    return <></>;
  }

  if (isLoading) {
    return (<LoadingSpinner />)
  }

  return (
    <>
      <div className="container max-w-[1400px] my-10">
        <div className="flex items-center justify-center my-10">

          <>
            <div className="grid grid-cols-1 w-full">
              <div className=""><table className="table w-full table-compact  rounded-none shadow-md lg:shadow-lg xl:shadow-xl" datatest-id="list-_table">
                <thead>
                  <tr className="border-b border-grey">
                    <th className="bg-white">Created</th>
                    <th className="bg-white">Name</th>
                    <th className="bg-white">Status</th>
                    <th className="bg-white">Locale</th>
                    <th className="bg-white pl-6">Open</th>
                    <th className="bg-white pl-6">Delete</th>
                  </tr>
                </thead>
                <tbody datatest-id="list_table_body">
                  {transcriptions?.map((transcription, index) => {
                    return (
                      <tr key={transcription.self} datatest-id={index}>
                        <td>{transcription.createdDateTime}</td>
                        <td>{transcription.displayName}</td>
                        <td>{transcription.status}</td>
                        <td>{transcription.locale}</td>
                        <td>
                          <button
                            className="btn btn-ghost font-bold"
                            onClick={() => openTranscription(transcription)}
                            datatest-id={index+"_open"}
                          >
                            Open
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-ghost font-bold"
                            onClick={() => onDeleteTranscription(transcription)}
                            datatest-id={index+"_delete"}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table></div>
              <div className="w-2/4 mx-auto">
                <Alert type="alert-error" message="All audio files and associated transcriptions will be removed after 30 days." />
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default ListTranscriptions;

import React, { useState } from "react";

interface TranscriptionRowProps {
  offset: string;
  display: string;
  channel: number;
  confidence: string;
  bgColor: string;
  index: number;
  onDisplayChange: (index: number, editedDisplay: string) => void;
  selectedColumns: {
    label: string | undefined;
    value: string;
  }[];
  speaker: number
  offsetInTicks: number
  handleTableRowClick: (offset: number) => void;
}

const TranscriptionRow: React.FC<TranscriptionRowProps> = ({
  offset,
  display,
  channel,
  confidence,
  bgColor,
  index,
  onDisplayChange,
  selectedColumns,
  speaker,
  offsetInTicks,
  handleTableRowClick
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleDisplayChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onDisplayChange(index, event.target.value);
  };

  const handleDoneClick = () => {
    setIsEditing(false);
  };


  return (
    <tr className="border-b">
      {selectedColumns.some((column) => column.value === "index") && (
        <td>{index}</td>
      )}
      {selectedColumns.some((column) => column.value === "speaker") && (
        <td>{speaker ? speaker : "-"}</td>
      )}
      {selectedColumns.some((column) => column.value === "channel") && (
        <td>{channel}</td>
      )}
      {selectedColumns.some((column) => column.value === "offset") && (
        <td datatest-id="offset">{offset}</td>
      )}
      {selectedColumns.some((column) => column.value === "confidence") && (
        <td>{confidence}</td>
      )}
      {selectedColumns.some((column) => column.value === "text") && (
        <td className={bgColor} datatest-id="display_text" >
          {isEditing ? (
            <textarea
              className="w-full h-full italic font-semibold"
              rows={4}
              value={display}
              onChange={(e) => handleDisplayChange(e)}
              datatest-id="display_textarea"
            />
          ) : (
            <p className="break-words whitespace-normal utterance" onClick={() => handleTableRowClick(offsetInTicks)} data-offset={offsetInTicks / 10000000}>{display}</p>
          )}
        </td>
      )}
      {selectedColumns.some((column) => column.value === "edit") && (
        <td>
          {isEditing ? (
            <button
              className="btn btn-ghost font-bold"
              onClick={handleDoneClick}
              datatest-id="done_button"
            >
              Done
            </button>
          ) : (
            <button
              className="btn btn-ghost font-bold"
              onClick={handleEditClick}
              datatest-id="edit_button"
            >
              Edit
            </button>
          )}
        </td>
      )}
    </tr>
  );
};

export default TranscriptionRow;

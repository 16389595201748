export const Locations: string[] = [
  "westus",
  "westus2",
  "eastus",
  "eastus2",
  "centralus",
  "northcentralus",
  "southcentralus",
  "centralindia",
  "eastasia",
  "southeastasia",
  "japaneast",
  "koreacentral",
  "australiaeast",
  "canadacentral",
  "northeurope",
  "westeurope",
  "uksouth",
  "francecentral",
];

export const Locales = [
  { label: "ar-AE", value: "ar-AE" },
  { label: "ar-BH", value: "ar-BH" },
  { label: "ar-EG", value: "ar-EG" },
  { label: "ar-KW", value: "ar-KW" },
  { label: "ar-QA", value: "ar-QA" },
  { label: "ar-SA", value: "ar-SA" },
  { label: "ar-SY", value: "ar-SY" },
  { label: "ca-ES", value: "ca-ES" },
  { label: "da-DK", value: "da-DK" },
  { label: "de-DE", value: "de-DE" },
  { label: "en-AU", value: "en-AU" },
  { label: "en-CA", value: "en-CA" },
  { label: "en-GB", value: "en-GB" },
  { label: "en-IN", value: "en-IN" },
  { label: "en-NZ", value: "en-NZ" },
  { label: "en-US", value: "en-US" },
  { label: "es-ES", value: "es-ES" },
  { label: "es-MX", value: "es-MX" },
  { label: "fi-FI", value: "fi-FI" },
  { label: "fr-CA", value: "fr-CA" },
  { label: "fr-FR", value: "fr-FR" },
  { label: "gu-IN", value: "gu-IN" },
  { label: "hi-IN", value: "hi-IN" },
  { label: "id-ID", value: "id-ID" },
  { label: "it-IT", value: "it-IT" },
  { label: "ja-JP", value: "ja-JP" },
  { label: "ko-KR", value: "ko-KR" },
  { label: "mr-IN", value: "mr-IN" },
  { label: "nb-NO", value: "nb-NO" },
  { label: "nl-NL", value: "nl-NL" },
  { label: "pl-PL", value: "pl-PL" },
  { label: "pt-BR", value: "pt-BR" },
  { label: "pt-PT", value: "pt-PT" },
  { label: "ru-RU", value: "ru-RU" },
  { label: "sv-SE", value: "sv-SE" },
  { label: "ta-IN", value: "ta-IN" },
  { label: "te-IN", value: "te-IN" },
  { label: "th-TH", value: "th-TH" },
  { label: "tr-TR", value: "tr-TR" },
  { label: "zh-CN", value: "zh-CN" },
  { label: "zh-HK", value: "zh-HK" },
  { label: "zh-TW", value: "zh-TW" },
];

export const PunctuationMode = [
  { label: "DictatedAndAutomatic", value: "DictatedAndAutomatic" },
  { label: "Automatic", value: "Automatic" },
  { label: "Dictated", value: "Dictated" },
  { label: "None", value: "None" },
];
export const ProfanityFilterMode = [
  { label: "Masked", value: "Masked" },
  { label: "None", value: "None" },
  { label: "Removed", value: "Removed" }
];

export const playbackRateOptions = [
  { value: "0.5", label: ".5" },
  { value: "0.7", label: ".7" },
  { value: "1", label: "1" },
  { value: "1.2", label: "1.2" },
  { value: "1.5", label: "1.5" },
  { value: "1.7", label: "1.7" },
  { value: "2", label: "2" },
];

export const displayChannelOptions = [
  {
    label: "Channel 0 and 1",
    value: "dual",
  },
  {
    label: "Channel 0",
    value: "0",
  },
  {
    label: "Channel 1",
    value: "1",
  },
];

export const columnOptions = [
  { value: "index", label: "Index" },
  { value: "speaker", label: "Speaker" },
  { value: "channel", label: "Channel" },
  { value: "offset", label: "Offset" },
  { value: "confidence", label: "Confidence" },
  { value: "text", label: "Utterance" },
  { value: "edit", label: "Edit Controls" },
];

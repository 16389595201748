import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faSignOut } from "@fortawesome/free-solid-svg-icons";
import griffithLogo from "../assets/griffithLogo.svg";
import { useAuth } from "react-oidc-context";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const auth = useAuth();
  const navigate = useNavigate()

  useEffect(() => {
    if (auth.isAuthenticated) {
      localStorage.setItem("access_token", auth.user?.access_token || "");
    }
  }, [auth.isAuthenticated, auth.user]);

  const signOutRedirect = () =>
    auth
      .revokeTokens(["access_token", "refresh_token"])
      .then(() => {
        console.log("tokens revoked");

        auth.removeUser().catch((e) => {
          console.error(e)
        });
        navigate('/')
      })
      .catch((_err) => Promise.reject("Logout Error: err"));

  return (
    <div className="container max-w-[1400px]">
      <div className="navbar bg-base-100">
        <div className="navbar-start m-3">
          <img src={griffithLogo} alt="Griffith logo" className="w-48 h-auto" />
        </div>
        <div className="navbar-end">
          {!auth.isAuthenticated && (
            <a
              className="btn btn-sm bg-custom-warning border-0 rounded hover:bg-custom-warning"
              datatest-id="signin"
              onClick={() => {
                auth.signinRedirect().catch((e) => console.error(e))
              }}
            >
              <FontAwesomeIcon icon={faCircleUser} className={"mr-2"} />
              Sign in
            </a>
          )}
          {auth.isAuthenticated && (
            <>
              <div className="grid grid-rows-2">
                <div className="">
                  Welcome,{" "}
                  {auth.user?.profile.given_name ||
                    auth.user?.profile.name ||
                    auth.user?.profile.preferred_username ||
                    auth.user?.profile.email}
                  !
                </div>
                <div className="">
                  {" "}
                  <a
                    className="btn btn-sm bg-white border-2 rounded border-custom-warning text-custom-warning hover:bg-white hover:border-custom-warning"
                    onClick={() => signOutRedirect()}
                    datatest-id="signout"
                  >
                    <FontAwesomeIcon icon={faSignOut} className={"mr-2"} />
                    Sign out
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Nav;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faInfoCircle,
  faList,
  faPen,
  faRefresh,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { deleteTranscription } from "../services/Transcription";
import { useAuth } from "react-oidc-context";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface MenuProps {
  onRefreshClicked: (location: any) => void;
  processAndCreateFile: (option: string) => void;
}


interface ConditionalLinkProps {
  to: string;
  onClick?: () => void;
  disabled: boolean;
  tooltip: string;
  icon: IconProp;
  dataTestId: string
}


const ConditionalLink: React.FC<ConditionalLinkProps> = ({
  to,
  onClick,
  disabled,
  tooltip,
  icon,
  dataTestId
}) => {
  const linkClasses = `tooltip tooltip-bottom ${disabled ? 'pointer-events-none' : ''
    }`;
  const iconClasses = `mx-3 ${disabled ? 'text-gray-400' : ''}`;

  return (
    <Link to={to} onClick={onClick} className={linkClasses} datatest-id={dataTestId} data-tip={tooltip}>
      <div className={iconClasses} >
        <FontAwesomeIcon icon={icon} />
      </div>
    </Link>
  );
};


function Menu(props: MenuProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const auth = useAuth()

  const handleRefreshClick = () => {
    props.onRefreshClicked(location);
  };

  const isTranscriptionPage = location.pathname.includes("transcriptions");
  const isDownloadMenuVisible = isTranscriptionPage && id;
  const isDeleteMenuVisible = isTranscriptionPage && id;

  const handleDownloadOptionClick = (option: string) => {
    props.processAndCreateFile(option);
  };

  // Define an array of download options with labels, descriptions, and option values
  const downloadOptions = [
    {
      label: "Download as text",
      description: "Preserves time stamp and text only",
      option: "text",
    },
    {
      label: "Download original transcript",
      description: "Saves original from transcription service",
      option: "original",
    },
    {
      label: "Download modified transcript data",
      description: "Saves all data including updated text",
      option: "modified",
    },
  ];

  const handleDelete = (id: string) => {
    const deleteTranscription$ = deleteTranscription(id);
    const sub = deleteTranscription$.subscribe({
      next: () => {
        navigate("/list-transcription");
      },
    });
    return () => sub.unsubscribe();
  }

  return (
    <>
      {/* Modal for download options */}
      <input type="checkbox" id="my-modal-6" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <div className="modal-content">
            <ul>
              {/* Render each download option */}
              {downloadOptions.map((option) => (
                <li key={option.label}>
                  <button
                    className="btn btn-block btn-ghost py-0 px-2 my-2 text-left justify-start"
                    onClick={() => handleDownloadOptionClick(option.option)}
                    datatest-id={option.option}
                  >
                    <div className="grid grid-cols-1 grid-rows-2 gap-1">
                      <div className="">{option.label}</div>
                      <div className="normal-case">
                        <small>{option.description}</small>
                      </div>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="modal-action">
            <label htmlFor="my-modal-6" className="btn btn-sm" datatest-id="cancel_button">
              Cancel
            </label>
          </div>
        </div>
      </div>
      {/* Main menu */}
      <div className="w-screen px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 py-3 menu menu-horizontal bg-custom-secondary text-white text-xl">
        <div className="container max-w-[1400px]">
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <div>
              <li>
                <p className="text-base sm:text-sm md:text-sm lg:text-xl xl:text-xl" onClick={() => navigate('/')}>Griffith Speech-to-Text Transcription Service</p>
              </li>
            </div>
            <div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">

              <ConditionalLink
                dataTestId="create_transcription"
                to="/create-transcription"
                disabled={!auth.isAuthenticated}
                tooltip="Create Transcription"
                icon={faPen}
              />

              <ConditionalLink
                dataTestId="list_transcription"
                to="/list-transcription"
                disabled={!auth.isAuthenticated}
                tooltip="List Transcriptions"
                icon={faList}
              />

              <ConditionalLink
                dataTestId="refresh_transcription"
                to="#"
                onClick={handleRefreshClick}
                disabled={!auth.isAuthenticated}
                tooltip="Refresh"
                icon={faRefresh}
              />

              {isDeleteMenuVisible && (
                <a className="cursor-pointer" datatest-id="delete_transcription">
                  <div className="tooltip tooltip-bottom" data-tip="Delete Transcription">
                    <FontAwesomeIcon icon={faTrashCan} onClick={() => handleDelete(id)} className={"mx-3"} />
                  </div>
                </a>
              )}
              {/* Show the download menu if it should be visible */}
              {isDownloadMenuVisible && (
                <a href="#" className="cursor-pointer" datatest-id="save_transcription">
                  <label htmlFor="my-modal-6">
                    <div className="tooltip tooltip-bottom" data-tip="Save Transcript Output">
                      <FontAwesomeIcon icon={faDownload} className={"mx-3 cursor-pointer"} /></div>
                  </label>
                </a>
              )}
              <Link
                to="https://griffitheduau.sharepoint.com/:b:/s/SpeechtoText/ERmD8Vid9FNKmArmKM99H8UBkkgOHxqDAl55y2MOZzVxBg"
                target="_blank"
              >
                <div className="tooltip tooltip-bottom" data-tip="Help">
                  <FontAwesomeIcon icon={faInfoCircle} className={"mx-3"} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;

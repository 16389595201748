import { useEffect, useState } from "react";
import { getLoginCount } from "../services/Sas";
import Layout from "./Layout";
import { LoginTranscriptionCount } from "../models/LoginTranscriptionCount";
import { useAuth } from "react-oidc-context";
import LoadingSpinner from "./LoadingSpinner";


const Home = () => {
  const [loginTranscriptionCount, setLoginTranscriptionCount] = useState<LoginTranscriptionCount | null>(null);
  const auth = useAuth()

  const loadLoginInfo = () => {
    const getLoginCount$ = getLoginCount();
    const sub = getLoginCount$.subscribe({
      next: async (res) => {
        !res?.message && setLoginTranscriptionCount(res);

      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    if (auth.user && auth.isAuthenticated) {
      loadLoginInfo();
    } else {
      setLoginTranscriptionCount(null)
    }
  }, [auth]);


  return (
    <>
      <Layout>
        {auth.isLoading && <LoadingSpinner />}

        {/* Render the login and transcription count */}
        <>
          <div className="w-screen px-44 py-3 text-xl">
            <div className="container max-w-[1400px] px-4">
              {loginTranscriptionCount &&
                <div className="flex justify-center">
                  <div className="card shadow-xl h-fit bg-neutral-100 mx-auto sm:w-full">
                    <div className="card-body" datatest-id="admin_info">
                      <h2 className="card-title text-[16px]" datatest-id="admin_info_title">Logged-in users and transcription counts in the last 30 days:</h2>
                      <div className="text-sm px-4 mt-2">
                        <p datatest-id="login_count">Login Count: {loginTranscriptionCount.login_count}</p>
                        <p datatest-id="transcription_count">Transcription Count: {loginTranscriptionCount.transcriptions_count}</p>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {!auth.isLoading && !auth.isAuthenticated &&
                <div className="alert shadow-lg">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <p>Please sign in to upload and transcribe your audio files.</p>
                  </div>
                </div>
              }
            </div>
          </div>
        </>

      </Layout>
    </>
  );
};

export default Home;

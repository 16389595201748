import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { TranscriptModels } from "../models/GetModels";
import { Model } from "../models/GetModel";
import { baseURL } from "./ServiceUtil";

export const getBaseModels = (locale?: string): Observable<TranscriptModels> =>
  ajax.getJSON<TranscriptModels>(`${baseURL}/models/?filter=locale eq '${locale}'`);

export const getModel = (id: string): Observable<Model> =>
  ajax.getJSON<Model>(`${baseURL}/models/${id}/`);

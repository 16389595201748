import React from 'react';

const LoadingSpinner: React.FC = () => {
    return (
        <div className="flex items-center justify-center my-10">
            <progress className="progress w-56"></progress>
        </div>
    )
};

export default LoadingSpinner;

import React, { useContext, useEffect, useRef, useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import { Params, useParams } from "react-router-dom";
import {
  RecognizedPhrase,
  TranscriptionData,
} from "../models/TranscriptionData";
import { Transcription } from "../models/Transcription";
import { getAccountSas } from "../services/Sas";
import Select from "react-select";
import TranscriptionRow from "./TranscriptionRow";
import moment from "moment";
import { Model } from "../models/GetModel";
import { useAuth } from "react-oidc-context";
import { LayoutContext } from "../contexts/LayoutContext";
import { saveAs } from "file-saver";
import { firstValueFrom, tap } from "rxjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { displayChannelOptions, columnOptions, playbackRateOptions } from "../data/options";
import { getAzureBlobSas } from "../azureUtils";
import LoadingSpinner from "./LoadingSpinner";
import { getTranscriptionResults, getTranscription } from "../services/Transcription";
import { getModel } from "../services/BaseModel";



/**
 * Combines two channels of recognized phrases into a single array, ordered by offset.
 * @param channel0 - Array of recognized phrases from channel 0.
 * @param channel1 - Array of recognized phrases from channel 1.
 * @returns Merged array of recognized phrases from both channels, sorted by offset.
 */
export const combineChannels = (channel0: RecognizedPhrase[], channel1: RecognizedPhrase[]) => {
  const mergedData = [];
  let pointer0 = 0;
  let pointer1 = 0;

  if (channel0.length && channel1.length) {
    while (pointer0 < channel0.length && pointer1 < channel1.length) {

      const channel0EntryOffset = moment.duration(channel0[pointer0].offset).seconds()
      const channel1EntryOffset = moment.duration(channel1[pointer1].offset).seconds()

      if (channel0EntryOffset === channel1EntryOffset) {
        mergedData.push(channel0[pointer0]);
        mergedData.push(channel1[pointer1])
        pointer0++;
        pointer1++;
      } else if (channel0EntryOffset < channel1EntryOffset) {
        mergedData.push(channel0[pointer0]);
        pointer0++;
      } else {
        mergedData.push(channel1[pointer1]);
        pointer1++;
      }
    }

    // Include remaining entries from channel0, if any
    while (pointer0 < channel0.length) {
      mergedData.push(channel0[pointer0]);
      pointer0++;
    }

    // Include remaining entries from channel1, if any
    while (pointer1 < channel1.length) {
      mergedData.push(channel1[pointer1]);
      pointer1++;
    }

    return mergedData
  }
  if (!channel0.length) return channel1
  else if (!channel1.length) return channel0
}



const TranscriptionDetail = () => {
  const auth = useAuth();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const transcriptId = useParams();

  const layoutContext = useContext(LayoutContext);
  const downloadOption = layoutContext?.downloadOption ?? ""
  const setDownloadOption = layoutContext?.setDownloadOption ?? (() => {})

  const [transcription, setTranscription] = useState<Transcription | null>(null);
  const [originalTranscriptionData, setOriginalTranscriptionData] = useState<TranscriptionData | null>(null);
  const [selectedSpeed, setSelectedSpeed] = useState(1);
  const [selectedDisplayChannel, setSelectedDisplayChannel] = useState<{ label: string | undefined; value: string; }>(displayChannelOptions[1]);
  const [enableHighlighting, setEnableHighlighting] = useState(false);
  const [autoScroll, setAutoScroll] = useState(false);
  const [redThreshold, setRedThreshold] = useState(82);
  const [yellowThreshold, setYellowThreshold] = useState(88);
  const [model, setModel] = useState<Model>();
  const [editedTranscriptionData, setEditedTranscriptionData] = useState<any>();
  const [contentUrl, setContentUrl] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<{ label: string | undefined; value: string; }[]>([columnOptions[3], columnOptions[5], columnOptions[6]]);
  const [channel0Entry, setChannel0Entry] = useState<RecognizedPhrase[]>()
  const [channel1Entry, setChannel1Entry] = useState<RecognizedPhrase[]>()
  const [combinedChannelEntries, setCombinedChannelEntries] = useState<RecognizedPhrase[]>()
  const [nextOffset, setNextOffset] = useState(0)
  const [audioUrl, setAudioUrl] = useState<string>("")

  // Function to convert a duration string to a formatted time string
  const convertDuration = (durationString: string) => {
    const duration = moment.duration(durationString);
    return moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
  };


  // useEffect hook to adjust audio playback speed when selectedSpeed changes
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = selectedSpeed;
      audioRef.current.defaultPlaybackRate = selectedSpeed;
    }
  }, [selectedSpeed]);

  useEffect(() => {
    return () => {
      // Set downloadOption to empty string value when the component unmounts
      setDownloadOption("");
    };
  }, [setDownloadOption]);

  useEffect(() => {
    setIsDataLoading(true);
    if (transcriptId.id && auth) {
      const getTranscriptionResults$ = getTranscriptionResults(transcriptId.id);
      const sub = getTranscriptionResults$
        .pipe(
          tap((res) => {
            setTranscription(res);
            const parts = res.model.self.split("/");
            const modelId = parts.pop();
            if (modelId) {
              const model$ = getModel(modelId);
              firstValueFrom(model$).then((model) => {
                setModel(model);
              }).catch((e) => console.error(e))
            }
            setContentUrl(res.contentUrl);
            const getTranscription$ = getTranscription(res.contentUrl);
            firstValueFrom(getTranscription$).then((originalTranscriptionData) => {
              originalTranscriptionData && setOriginalTranscriptionData(originalTranscriptionData);
              retrieveAndProcessModifiedTranscriptData();
            }).catch((e) => console.error(e))
          })
        )
        .subscribe(() => {
          setIsDataLoading(false);
        });
      return () => sub.unsubscribe();
    }
  }, [transcriptId, auth]);

  useEffect(() => {
    const fetchData = async (source: string) => {

      // get account sas to access the audio file
      const res = await firstValueFrom(getAccountSas());
      const data = res?.response as any;
      const url = `${source}?${data.sas}`;
      setAudioUrl(url)
    };

    originalTranscriptionData?.source && fetchData(originalTranscriptionData.source);

    return () => {
    };
  }, [originalTranscriptionData]);

  // Retrieves and processes the modified transcript data from Azure Blob Storage.
  const retrieveAndProcessModifiedTranscriptData = () => {
    const filename = createFilenameTranscript(`channel.json`, transcriptId);
    getAccountSas()
      .toPromise()
      .then(async (res) => {
        const data = res?.response as any;
        const blobServiceClient = new BlobServiceClient(
          `https://${(window as any)?.__RUNTIME_CONFIG__?.azure_storage_account_name}.blob.core.windows.net/?${data.sas}`
        );
        const containerClient =
          blobServiceClient.getContainerClient("transcripts");
        const blobClient = containerClient.getBlockBlobClient(filename);
        const downloadBlockBlobResponse = await blobClient.download();
        if (downloadBlockBlobResponse?.blobBody) {
          const data = await blobToString(
            await downloadBlockBlobResponse.blobBody
          );
          if (data) {
            setEditedTranscriptionData(JSON.parse(data));
          }
        }
      })
      .catch((e) => {
        console.log('Error occurred:', e.toString());
      });
  };

  /**
 * Converts a Blob object to a string.
 * @param blob - The Blob object to convert.
 * @returns A Promise that resolves to the string representation of the Blob.
 */
  const blobToString = async (blob: Blob): Promise<string> => {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (event: any) => {
        resolve(event.target.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  };

  useEffect(() => {
    const filterByChannel = (phrases: RecognizedPhrase[], channel: number) => phrases.filter(entry => entry.channel === channel);

    // Set channel entries for transcription data
    if (originalTranscriptionData?.recognizedPhrases) {
      const channel0Phrases = filterByChannel(originalTranscriptionData.recognizedPhrases, 0);
      const channel1Phrases = filterByChannel(originalTranscriptionData.recognizedPhrases, 1);
      setChannel0Entry(channel0Phrases);
      setChannel1Entry(channel1Phrases);
      const data = combineChannels(channel0Phrases, channel1Phrases)
      setCombinedChannelEntries(data)
    }

    // Set channel entries for edited transcription data
    if (editedTranscriptionData?.transcriptData?.recognizedPhrases) {
      const channel0Phrases = filterByChannel(editedTranscriptionData.transcriptData.recognizedPhrases, 0);
      const channel1Phrases = filterByChannel(editedTranscriptionData.transcriptData.recognizedPhrases, 1);
      setChannel0Entry(channel0Phrases);
      setChannel1Entry(channel1Phrases);
      const data = combineChannels(channel0Phrases, channel1Phrases)
      setCombinedChannelEntries(data)
    }
  }, [originalTranscriptionData, editedTranscriptionData]);


  useEffect(() => {
    if (downloadOption === "text") {
      processAndCreateTextFile();
    }
    if (downloadOption === "original") {
      const newTab = window.open("", "_blank");
      newTab!.location.href = contentUrl;
    }
    if (downloadOption === "modified") {
      downloadModifiedResults();
    }
  }, [downloadOption]);


  // Processes the transcription data and creates a file for download.
  const processAndCreateTextFile = () => {
    const filename = "transcript.txt";
    const fileType = "text/plain";

    const data = editedTranscriptionData
      ? editedTranscriptionData?.transcriptData
      : originalTranscriptionData;

    let sections;
    if (selectedDisplayChannel.value === "dual") {
      sections = data?.recognizedPhrases.map((data: RecognizedPhrase) => formatSection(data));
    } else {
      sections = data?.recognizedPhrases
        .filter((data: RecognizedPhrase) => data.channel === Number(selectedDisplayChannel.value))
        .map((data: RecognizedPhrase) => formatSection(data));
    }
    const blob = new Blob(sections, { type: fileType });
    saveAs(blob, filename);
  };

  const formatSection = (data: RecognizedPhrase) => {
    let sectionString = ""; // Accumulates the formatted section string

     // Check if the speaker value exists
     if (data["speaker"]) {
      sectionString += `${data["speaker"]}\t`;
    }

    // Check if the offset value exists and convert it using the convertDuration function
    if (data["offset"]) {
      const offsetValue = convertDuration(data["offset"]);
      sectionString += `${offsetValue}\t`;
    }

    // Check if the display value exists and append it to sectionString
    if (data.nBest[0]["display"]) {
      sectionString += `${data.nBest[0]["display"]}\n`;
    }

    // Return the trimmed section string with a trailing newline character
    return `${sectionString.trim()}\n`;
  };

  // Function to download modified results as a JSON file
  const downloadModifiedResults = () => {
    const filename = "traningdata.json";
    const fileType = "application/json";

    // Create a JSON string representation of the edited transcription data if available,
    // otherwise use the original transcription data
    const json = editedTranscriptionData
      ? JSON.stringify(editedTranscriptionData)
      : JSON.stringify(originalTranscriptionData);

    const blob = new Blob([json], { type: fileType });
    saveAs(blob, filename);
  };

  // Determines the color class based on the confidence value.
  const getColor = (confidence: number) => {
    let result = "white";
    if (enableHighlighting) {
      confidence = confidence * 100;
      result = "bg-green-400";
      if (confidence < redThreshold) {
        result = "bg-red-300";
      } else if (confidence < yellowThreshold) {
        result = "bg-[#eee8aa]";
      }
    }
    return result;
  };


  const uploadFile = async (sasToken: string, filename: string, file: File) => {
    const blobServiceClient = new BlobServiceClient(
      `https://${(window as any)?.__RUNTIME_CONFIG__?.azure_storage_account_name}.blob.core.windows.net/?${sasToken}`
    );
    const containerClient = blobServiceClient.getContainerClient("transcripts");
    const blockBlobClient = containerClient.getBlockBlobClient(`${filename}`);
    const fileBlob = new Blob([file], { type: file?.type });
    try {
      await blockBlobClient.uploadData(fileBlob, {
        blobHTTPHeaders: { blobContentType: file?.type },
      });
    } catch (error) {
      // Handle potential errors or rejections here
      console.error("Upload failed:", error);
    }
  };


  // Function to handle the change of the selected playback speed
  const handleSpeedChange = (value: string) => {
    const speed = parseFloat(value);
    setSelectedSpeed(speed);
  };

  // Handles saving the modified transcription by updating and uploading the transcription data.
  const handleSaveModifiedTranscription = () => {
    const file = createTranscriptFile();
    const filename = createFilenameTranscript(file.name, transcriptId);

    getAzureBlobSas(`${filename}`, "transcripts")
      .then((sasToken) => {
        const toastId = toast.loading("Please wait...");
        return uploadFile(sasToken, filename, file)
          .then(() => {
            toast.update(toastId, {
              render: "Transcription saved successfully.",
              type: "success",
              isLoading: false,
              autoClose: 3000,
              closeButton: true,
              hideProgressBar: false,
              theme: "colored",
            });
          })
          .catch((e) => {
            console.error(e);
            toast.update(toastId, {
              render: "Error! Something went wrong.",
              type: "error",
              isLoading: false,
              autoClose: 3000,
              closeButton: true,
              hideProgressBar: false,
              theme: "colored",
            });
          });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  // Creates the filename for the transcript by combining the username, transcript ID, and the original filename.
  const createFilenameTranscript = (
    filename: string,
    transcriptId: Readonly<Params<string>>
  ) => {
    return `${auth.user?.profile.preferred_username}/${transcriptId.id}/${filename}`;
  };

  // Creates the transcript file to be uploaded.
  const createTranscriptFile = () => {
    let recognizedPhrases: RecognizedPhrase[] = [];

    if (channel0Entry && channel0Entry.length > 0) {
      recognizedPhrases = recognizedPhrases.concat(channel0Entry);
    }
    if (channel1Entry && channel1Entry.length > 0) {
      recognizedPhrases = recognizedPhrases.concat(channel1Entry);
    }

    const editedTranscriptionData = originalTranscriptionData ? { ...originalTranscriptionData, recognizedPhrases: recognizedPhrases } : []
    const data = { transcript: transcription, transcriptData: editedTranscriptionData };

    const blob = new Blob([JSON.stringify(data, null, 1)], { type: "application/json" });
    const file: any = blob;
    file.lastModifiedDate = new Date();
    file.name = `channel.json`;

    return file as File;
  };


  // Updates the display value of a specific index in the channel entries.
  const handleDisplayChange = (index: number, editedDisplay: string) => {
    let updatedChannelEntry;


    if (selectedDisplayChannel.value === "0") {
      updatedChannelEntry = channel0Entry ? [...channel0Entry] : [];
      updatedChannelEntry[index].nBest[0].display = editedDisplay;
      setChannel0Entry(updatedChannelEntry)
      const data = channel1Entry && combineChannels(updatedChannelEntry, channel1Entry)
      setCombinedChannelEntries(data)
    } else if (selectedDisplayChannel.value === "1") {
      updatedChannelEntry = channel1Entry ? [...channel1Entry] : [];
      updatedChannelEntry[index].nBest[0].display = editedDisplay;
      setChannel1Entry(updatedChannelEntry)
      const data = channel0Entry && combineChannels(channel0Entry, updatedChannelEntry)
      setCombinedChannelEntries(data)

    }
    else if (selectedDisplayChannel.value === 'dual') {
      const updatedMergedData = combinedChannelEntries ? [...combinedChannelEntries] : [];
      updatedMergedData[index].nBest[0].display = editedDisplay;
      setCombinedChannelEntries(updatedMergedData)
    }
  };


  const highlightUtterance = () => {
    const currentTime = audioRef.current?.currentTime || 0

    if (currentTime < nextOffset) {
      return;
    }
    const utterances = document.getElementsByClassName('utterance');
    for (let i = 0; i < utterances.length; i++) {
      const u1 = utterances[i];
      const offset = Number(u1.getAttribute('data-offset'));
      let next = 0;
      if (i + 1 < utterances.length) {
        const u2 = utterances[i + 1];
        next = Number(u2.getAttribute('data-offset'));
      } else {
        next = offset + 30;
      }

      if (currentTime > offset && currentTime < next) {

        utterances[i].classList.add('bg-[#ffebcd]');
        if (autoScroll) {
          utterances[i].scrollIntoView({ block: 'center' });
        }
        setNextOffset(next)
      } else {
        utterances[i].classList.remove('bg-[#ffebcd]');
      }
    }
  }
  if (isDataLoading || !transcription || !originalTranscriptionData) {
    return (
      <LoadingSpinner />
    );
  }

  const handleTableRowClick = (offsetInTicks: number) => {
    const offsetInSeconds = offsetInTicks / 10000000;
    if (audioRef.current) {
      audioRef.current.currentTime = offsetInSeconds;
      if (audioRef.current.currentTime > 0) {
        audioRef.current.play().catch((error) => {
          // Handle potential error during playback
          console.error("error:", error);
        });
      }
    }
  };


  return (
    <>
      <div className="container max-w-[1400px]">
        <ToastContainer />
        <div className="prose max-w-none flex items-center justify-center">
          <div>
            {" "}
            <h2 datatest-id="title">{transcription?.displayName}</h2>
          </div>
        </div>
        <div className="max-w-none flex items-center justify-center">
          <div>
            {" "}
            <strong datatest-id="status">(Status: {transcription?.status})</strong>
          </div>
        </div>

        <div className="flex items-center justify-center my-10">
          <table className="table w-full rounded-none">
            <thead>
              <tr>
                <th>File</th>
                <th>Locale</th>
                <th>Duration</th>
                <th>Diarization</th>
                <th>Word Level Timestamps</th>
                <th>Punctuation Mode</th>
                <th>Profanity Mode</th>
                <th>Models</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="underline text-blue-600">
                  <a href={audioUrl} datatest-id="file">
                    {decodeURIComponent(originalTranscriptionData?.source.split("/").pop() || "")}
                  </a>
                </td>
                <td datatest-id="locale">{transcription?.locale}</td>
                <td datatest-id="duration">
                  {transcription?.properties?.duration &&
                    convertDuration(transcription?.properties?.duration)}
                </td>
                <td datatest-id="diarization_enabled">
                  {transcription?.properties?.diarizationEnabled
                    ? "True"
                    : "False"}
                </td>
                <td datatest-id="word_level_timestamps_enabled">
                  {transcription?.properties?.wordLevelTimestampsEnabled
                    ? "True"
                    : "False"}
                </td>
                <td datatest-id="punctuation_mode">{transcription?.properties.punctuationMode}</td>
                <td datatest-id="profanity_filter_mode">{transcription?.properties.profanityFilterMode}</td>
                <td datatest-id="model_name">{model?.displayName ? model?.displayName : "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <div className="flex cursor-pointeralign-items justify-start py-2">
            <input
              type="checkbox"
              className="checkbox"
              onChange={() => setAutoScroll(!autoScroll)}
            />
            <span className="pl-3">Auto Scroll</span>
          </div>
          <div className="flex cursor-pointeralign-items justify-start py-2">
            <input
              type="checkbox"
              className="checkbox"
              onChange={() => setEnableHighlighting(!enableHighlighting)}
            />
            <span className="pl-3">Show Confidence Highlighting</span>
          </div>
          <table className={enableHighlighting ? "" : "hidden"}>
            <tbody>
              <tr className="border-4 border-white">
                <td className="bg-red-300 px-6"></td>
                <td>
                  <input
                    className="w-1/4 pl-2"
                    type="text"
                    value={redThreshold}
                    onChange={(e) => setRedThreshold(Number(e.target.value))}
                  />
                </td>
              </tr>
              <tr className="border-4 border-white">
                <td className="bg-[#eee8aa] w-30"></td>
                <td>
                  <input
                    className="w-1/4 pl-2"
                    type="text"
                    value={yellowThreshold}
                    onChange={(e) => setYellowThreshold(Number(e.target.value))}
                  />
                </td>
              </tr>
              <tr className="border-4 border-white">
                <td className="bg-green-400 w-30"></td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex">
          <div className="w-1/4">
            <label className="label">
              <span className="label-text">Columns</span>
            </label>
            <Select
              className="z-3 basic-multi-select"
              isMulti
              options={columnOptions}
              value={selectedColumns}
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions.map((option) => option);
                setSelectedColumns(selectedValues);
              }}
            />
          </div>
        </div>
        <div className="w-1/4 my-3">
          <label className="label">
            <span className="label-text">Playback Speed</span>
          </label>
          <Select
            className="z-2"
            value={playbackRateOptions.filter(
              (option) => option.value === String(selectedSpeed)
            )}
            options={playbackRateOptions}
            onChange={(option) => option && handleSpeedChange(option.value)}
          />
        </div>

        <div className="flex">
          <div className="w-1/4">
            <label className="label">
              <span className="label-text">Display Channel</span>
            </label>
            <Select
              className="z-1"
              value={selectedDisplayChannel}
              options={displayChannelOptions}
              onChange={(option) => option && setSelectedDisplayChannel(option)}
            />
          </div>
          <div className="w-3/4 flex items-center justify-end">
            <button
              className="bg-custom-primary btn-wide hover:bg-custom-primary text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-auto hover:drop-shadow-lg"
              onClick={() => handleSaveModifiedTranscription()}
              datatest-id="save_modified_transcript"
            >
              Save modified transcription
            </button>
          </div>
        </div>

        <div className="flex items-center justify-center my-10 ">
          <table className="table table-compact w-full z-0">
            <thead>
              <tr>
                {selectedColumns.some((column) => column.value === "index") && (
                  <th>Index</th>
                )}
                {selectedColumns.some((column) => column.value === "speaker") && (
                  <th>Speaker</th>
                )}
                {selectedColumns.some(
                  (column) => column.value === "channel"
                ) && <th>Channel</th>}
                {selectedColumns.some(
                  (column) => column.value === "offset"
                ) && <th>Offset</th>}
                {selectedColumns.some(
                  (column) => column.value === "confidence"
                ) && <th>Confidence</th>}
                {selectedColumns.some((column) => column.value === "text") && (
                  <th>Text</th>
                )}
                {selectedColumns.some((column) => column.value === "edit") && (
                  <th>Edit</th>
                )}
              </tr>
            </thead>
            <tbody>
              {originalTranscriptionData?.recognizedPhrases &&
                combinedChannelEntries && selectedDisplayChannel?.value === 'dual' &&
                combinedChannelEntries.map((entry, index) => {
                  return (
                    <React.Fragment key={entry.offset}>
                      {(selectedDisplayChannel?.value === '0' || selectedDisplayChannel?.value === '1' || selectedDisplayChannel?.value === 'dual') && <TranscriptionRow
                        handleTableRowClick={(offsetInTicks) => handleTableRowClick(offsetInTicks)}
                        selectedColumns={selectedColumns}
                        onDisplayChange={(index, editedDisplay) => {
                          handleDisplayChange(index, editedDisplay)
                        }
                        }
                        index={index}
                        speaker={entry.speaker}
                        channel={entry.channel}
                        offsetInTicks={entry.offsetInTicks}
                        offset={convertDuration(entry.offset)}
                        display={entry.nBest[0].display}
                        confidence={`${(entry.nBest[0].confidence * 100).toFixed(0)}%`}
                        bgColor={getColor(entry.nBest[0].confidence)}
                      />}
                    </React.Fragment>
                  );

                })}

              {originalTranscriptionData?.recognizedPhrases &&
                channel0Entry &&
                channel0Entry.map((entry, index) => {
                  return (
                    <React.Fragment key={entry.offset}>
                      {(selectedDisplayChannel?.value === '0') && <TranscriptionRow
                        handleTableRowClick={(offsetInTicks) => handleTableRowClick(offsetInTicks)}
                        selectedColumns={selectedColumns}
                        onDisplayChange={(index, editedDisplay) => {
                          handleDisplayChange(index, editedDisplay)
                        }
                        }
                        index={index}
                        speaker={entry.speaker}
                        channel={entry.channel}
                        offsetInTicks={entry.offsetInTicks}
                        offset={convertDuration(entry.offset)}
                        display={entry.nBest[0].display}
                        confidence={`${(entry.nBest[0].confidence * 100).toFixed(0)}%`}
                        bgColor={getColor(entry.nBest[0].confidence)}
                      />}
                    </React.Fragment>
                  );

                })}

              {originalTranscriptionData?.recognizedPhrases &&
                channel1Entry &&
                channel1Entry.map((entry, index) => {
                  return (
                    <React.Fragment key={entry.offset}>
                      {(selectedDisplayChannel?.value === '1') && <TranscriptionRow
                        handleTableRowClick={(offsetInTicks) => handleTableRowClick(offsetInTicks)}
                        selectedColumns={selectedColumns}
                        onDisplayChange={(index, editedDisplay) => {
                          handleDisplayChange(index, editedDisplay)
                        }
                        }
                        index={index}
                        speaker={entry.speaker}
                        channel={entry.channel}
                        offsetInTicks={entry.offsetInTicks}
                        offset={convertDuration(entry.offset)}
                        display={entry.nBest[0].display}
                        confidence={`${(entry.nBest[0].confidence * 100).toFixed(0)}%`}
                        bgColor={getColor(entry.nBest[0].confidence)}
                      />}
                    </React.Fragment>
                  );

                })}

            </tbody>
          </table>
        </div>
        {originalTranscriptionData != null && audioUrl && (
          <>
            <audio ref={audioRef} id="audio" controls className="w-full my-10 sticky bottom-0" onTimeUpdate={() => highlightUtterance()} onSeeked={() => setNextOffset(0)}>
              <source src={audioUrl} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </>
        )}
      </div>
    </>
  );
};
export default TranscriptionDetail;

import { AnonymousCredential, BlobServiceClient } from "@azure/storage-blob";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import {
  getAccountSas,
} from "../services/Sas";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { PostTranscriptionForm } from "../models/PostTranscriptionForm";
import { Locales, ProfanityFilterMode, PunctuationMode } from "../data/options";
import Select from "react-select";
import { useAuth } from "react-oidc-context";
import { AudioUrlsContext } from "../contexts/AudioUrlsContext";
import { TranscriptModels } from "../models/GetModels";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAzureBlobSas } from "../azureUtils";
import Alert from "./Alert";
import { postTranscription } from "../services/Transcription";
import { getBaseModels } from "../services/BaseModel";
import { firstValueFrom } from "rxjs";

const defaultData = {
  data: {
    locale: "en-AU",
  },
};

type UploadFileModelProps = {
  showModel: boolean;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  file: File | null;
  isLoading: boolean;
  onCancelClick: () => void;
  onUploadClick: () => void;
};

const UploadFileModel: React.FC<UploadFileModelProps> = ({
  showModel,
  handleFileChange,
  file,
  isLoading,
  onCancelClick,
  onUploadClick,
}) => {
  return (
    <>
      <input
        type="checkbox"
        id="my-modal"
        className="modal-toggle"
        checked={showModel}
        onChange={() => null}
      />
      <div className="modal">
        <div className="modal-box rounded-md">
          <div className="flex items-center justify-center">
            <button
              datatest-id="choose_file"
              className="bg-white text-black shadow-lg font-semibold py-2 px-4 rounded"
              onClick={() => {
                const fileInput = document.getElementById("file");
                if (fileInput instanceof HTMLInputElement) {
                  fileInput.click();
                }
              }}
            >
              Choose File
            </button>
          </div>
          <input
            id="file"
            datatest-id="upload_file_input"
            type="file"
            accept=".wav, .mp3, .ogg"
            className="hidden"
            onChange={(e) => handleFileChange(e)}
          />

          {!file && (
            <>
              <div className="flex items-center justify-center mt-4">
                No file has been selected
              </div>

              <div className="flex items-center justify-center mt-4">
                <a
                  className="underline text-blue-600 visited:text-purple-600"
                  href="https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/batch-transcription#the-batch-transcription-api"
                >
                  {" "}
                  Accepted file types: WAV, MP3, OGG{" "}
                </a>
              </div>
            </>
          )}
          {file && (
            <div className="flex items-center justify-center mt-4">
              {file.name}
            </div>
          )}
          <div className="modal-action flex items-center justify-center">
            {!isLoading && (
              <>
                {" "}
                <label
                  htmlFor="my-modal"
                  className="bg-custom-warning hover:bg-custom-warning text-white text-sm font-semibold py-2 px-3 my-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={onCancelClick}
                >
                  Cancel
                </label>
                <label
                  datatest-id="upload_button"
                  className={`bg-custom-primary hover:bg-custom-primary text-white text-sm font-semibold py-2 px-3 my-4 rounded focus:outline-none focus:shadow-outline ${!file ? 'btn-disabled bg-slate-400/50' : ''
                    }`}
                  onClick={onUploadClick}
                >
                  Upload
                </label>

              </>
            )}
            {isLoading && <progress className="progress w-56"></progress>}
          </div>
        </div>
      </div>
    </>
  );
};

const CreateTranscription = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<PostTranscriptionForm>({
    defaultValues: defaultData,
  });

  const auth = useAuth();
  const currentAudioUrls = useContext(AudioUrlsContext);

  const [folderName, setFolderName] = useState<string>("");
  const [audioUrls, setAudioUrls] = useState<
    { label: string | undefined; value: string }[]
  >(currentAudioUrls || []);
  const [showModel, setShowModel] = useState<boolean>(false);
  const [blobName, setBlobName] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [selectedFile, setSelectedFile] = useState<{
    label: string | undefined;
    value: string;
  }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [filteredBaseModels, setFilteredBaseModels] = useState<
    { label: string | undefined; value: string }[]
  >([]);
  const [baseModels, setBaseModels] = useState<TranscriptModels>();
  const [maxCountError, setMaxCountError] = useState<string>('')
  const locale = watch("data.locale", "en-AU");

  useEffect(() => {
    setAudioUrls(currentAudioUrls);
  }, [currentAudioUrls]);


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      setBlobName(event.target.files[0].name);
    }
  };

  const uploadFile = async (sasToken: string) => {
    const blobServiceClient = new BlobServiceClient(
      `https://${(window as any)?.__RUNTIME_CONFIG__?.azure_storage_account_name}.blob.core.windows.net/?${sasToken}`
    );

    const containerClient = blobServiceClient.getContainerClient("audio");

    const blockBlobClient = containerClient.getBlockBlobClient(
      `${auth.user?.profile.preferred_username}/${blobName}`
    );

    const fileBlob = new Blob([file as File], { type: file?.type });
    blockBlobClient.uploadData(fileBlob, {
      blobHTTPHeaders: { blobContentType: file?.type },
    }).catch((e) => {
      console.error(e)
    });

  };

  const getContainerClient = async (
    sasToken: string,
    containerName: string
  ) => {
    const blobServiceClient = new BlobServiceClient(
      `https://${(window as any)?.__RUNTIME_CONFIG__?.azure_storage_account_name}.blob.core.windows.net/?${sasToken}`,
      new AnonymousCredential()
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);
    return containerClient;
  };

  const listAudioFiles = async (sasToken: string) => {
    const containerClient = await getContainerClient(sasToken, "audio");

    // Set the prefix of the blobs you want to retrieve
    const prefix = `${folderName}/`;

    // Get an iterator that lists all the blobs in the container with the specified prefix
    const iterator = containerClient.listBlobsFlat({ prefix });
    const audioUrls = [];

    // Iterate over the blobs and add the URLs of the audio files to the array
    for await (const blob of iterator) {
      const blobUrl = `https://${(window as any)?.__RUNTIME_CONFIG__?.azure_storage_account_name}.blob.core.windows.net/audio/${blob.name}`;
      audioUrls.push(blobUrl);
    }

    return audioUrls;
  };

  const getAudioFiles = async () => {
    try {
      const res = await firstValueFrom(getAccountSas());
      const data = res?.response as any;
      const urls = await listAudioFiles(data.sas);
      const options: { label: string | undefined; value: string }[] = [];
      urls.forEach((url) => {
        options.push({ label: url.split("/").pop(), value: url });
      });
      setAudioUrls(options);
      urls[0] && setSelectedFile({ label: urls[0].split("/").pop(), value: urls[0] });
      setValue("data.contentUrls", [urls[0]]);
    } catch (e) {
      // Handle the error
      console.error(e)
    }
  };

  useEffect(() => {
    getAudioFiles().catch((e) => console.error(e))
  }, [folderName]);

  useEffect(() => {
    if (auth.user && auth.isAuthenticated) {
      loadModels();
      auth.user.profile.preferred_username &&
        setFolderName(auth.user?.profile.preferred_username);
    }
  }, [auth]);

  const loadModels = () => {
    const getTranscriptBaseModels$ = getBaseModels(locale);
    const sub = getTranscriptBaseModels$.subscribe({
      next: (res) => {
        setBaseModels(res);
      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    if (baseModels) {
      const modelOptions: { label: string | undefined; value: string }[] = [];
      baseModels.forEach((model) => {
        modelOptions.push({ label: model.displayName, value: model.self });
      });
      setFilteredBaseModels(modelOptions);
    }
  }, [baseModels]);

  useEffect(() => {
    loadModels();
  }, [locale]);

  const onUploadClick = () => {
    const toastId = toast.loading("Please wait...");
    setIsLoading(true);
    getAzureBlobSas(
      `${auth.user?.profile.preferred_username}/${blobName}`,
      "audio"
    )
      .then((sasToken: string) => {
        uploadFile(sasToken).then(() => {
          const url = `https://${(window as any)?.__RUNTIME_CONFIG__?.azure_storage_account_name}.blob.core.windows.net/audio/${auth.user?.profile.preferred_username}/${blobName}`;
          audioUrls.push({ label: blobName, value: url });
          setSelectedFile({ label: blobName, value: url });
          setValue("data.contentUrls", [url]);
          toast.update(toastId, {
            render: "File uploaded successfully.",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
            theme: "colored",
          });
        }).catch((e) => {
          console.error(e)
        })
      })
      .catch((err) => {
        // Handle the error
      });
    setShowModel(false);
    setIsLoading(false);
  };

  const submitForTranscription = (data: PostTranscriptionForm) => {
    const payloadData = data.data;
    const payload = {
      data: {
        contentUrls: payloadData.contentUrls,
        locale: payloadData.locale,
        displayName: payloadData.displayName,
        model: payloadData.model
          ? {
            self: payloadData.model.self,
          }
          : null,
        properties: {
          punctuationMode: payloadData.properties.punctuationMode,
          profanityFilterMode: payloadData.properties.profanityFilterMode,
          diarizationEnabled: payloadData.properties.diarizationEnabled,
          ...(payloadData.properties.diarizationEnabled
            ? {
              diarization: {
                speakers: {
                  minCount: payloadData.properties.diarization ? payloadData.properties.diarization?.speakers.minCount : 1,
                  maxCount: payloadData.properties.diarization ? payloadData.properties.diarization?.speakers.maxCount : 1,
                },
              },
            }
            : {}),
          wordLevelTimestampsEnabled:
            payloadData.properties.wordLevelTimestampsEnabled,
        },
      },
    }

    const postTranscription$ = postTranscription(payload);
    const sub = postTranscription$.subscribe({
      next: () => {
        navigate("/list-transcription");
      },
    });
    return () => sub.unsubscribe();
  };

  const onSubmit: SubmitHandler<PostTranscriptionForm> = (data) => {
    if (!selectedFile) {
      return;
    }

    submitForTranscription(data);
  };

  if (!auth.isAuthenticated) {
    return <></>;
  }

  return (
    <>
      {showModel && <UploadFileModel showModel={showModel} handleFileChange={(event: ChangeEvent<HTMLInputElement>) => handleFileChange(event)} file={file} isLoading={isLoading} onCancelClick={() => setShowModel(false)} onUploadClick={onUploadClick} />}

      <div className="container max-w-[1400px]">
        <div className="flex items-center justify-center my-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-5 border-2 border-[#909090]">
              <div className="mb-4">
                <div className="form-control w-full my-3">
                  <label className="label">
                    <span className="label-text">
                      Select File<span className="text-red-600">*</span>
                    </span>
                  </label>
                  {selectedFile && (
                    <Select
                      options={audioUrls}
                      defaultValue={selectedFile}
                      onChange={(option) => {
                        option?.value &&
                          setValue("data.contentUrls", [option?.value]);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="bg-custom-primary hover:bg-custom-primary text-white text-sm font-semibold py-2 px-2 my-4 rounded focus:outline-none hover:drop-shadow-lg"
                  htmlFor="my-modal"
                  onClick={() => setShowModel(true)}
                  datatest-id="upload_file"
                >
                  Upload New File
                </label>
              </div>

              <div className="form-control w-full my-3">
                <label className="label">
                  <span className="label-text">
                    Title<span className="text-red-600">*</span>
                  </span>
                </label>
                <input
                  className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none hover:shadow-outline ${errors.data?.displayName ? "border-red-500" : ""
                    }`}
                  id="title"
                  datatest-id="title"
                  type="text"
                  placeholder="Enter a title"
                  {...register("data.displayName", {
                    required: "Please enter title.",
                  })}
                />

                <span className="text-red-600 text-sm">
                  {errors.data?.displayName?.message}
                </span>
              </div>

              <div
                className={`collapse ${isCollapsed ? "collapsed" : ""} m-0 p-0`}
              >
                <input
                  type="checkbox"
                  className="peer m-0 p-0"
                  datatest-id="advance_button"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                />
                <div className="collapse-title p-0 my-1 min-h-0">
                  <label
                    className="bg-custom-warning hover:bg-custom-warning text-white text-sm font-semibold py-2 px-2 my-4 rounded focus:outline-none hover:drop-shadow-lg"
                    htmlFor="my-modal"
                  >
                    {isCollapsed ? "Advance" : "Hide Advance"}
                  </label>
                </div>
                <div className="collapse-content m-0 p-0">
                  <div className="form-control w-full">
                    <label className="label">
                      <span className="label-text">Locale</span>
                    </label>
                    <Select
                      id="locale"
                      options={Locales}
                      defaultValue={Locales.find(
                        (item) => item.value === "en-AU"
                      )}
                      {...register("data.locale")}
                      onChange={(option) => {
                        option && setValue("data.locale", option.value);
                      }}
                    />
                  </div>
                  <div className="my-5">
                    <div className="flex cursor-pointeralign-items justify-start">
                      <input
                        datatest-id="diarization"
                        type="checkbox"
                        className="checkbox"
                        {...register("data.properties.diarizationEnabled")}
                      />
                      <span className="label-text pl-3">Diarization</span>
                      <input
                        datatest-id="word_level_timestamp"
                        type="checkbox"
                        className="checkbox ml-3"
                        {...register(
                          "data.properties.wordLevelTimestampsEnabled"
                        )}
                      />
                      <span className="label-text pl-3">
                        Add word level timestamps
                      </span>
                    </div>
                  </div>
                  {watch("data.properties.diarizationEnabled") && <div className="my-5">

                    <div className="flex items-center justify-start">
                      <label className="label w-full py-2 mr-2">
                        <span className="label-text">
                          Minimum #Speakers
                        </span>
                      </label>
                      <label className="label w-full">
                        <span className="label-text">
                          Maximum #Speakers
                        </span>
                      </label>
                    </div>
                    <div className="flex cursor-pointeralign-items justify-start">
                      <input
                        className={`appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none hover:shadow-outline ${errors.data?.displayName ? "border-red-500" : ""
                          }`}
                        id=""
                        datatest-id="min_count"
                        defaultValue={1}
                        type="number"
                        min={1}
                        max={35}
                        onKeyDown={(e) => e.preventDefault()}
                        placeholder="Enter a min speaker count"
                        {...register("data.properties.diarization.speakers.minCount", {
                          required: "Please enter a minCount.",
                        })}
                      />

                      <input
                        className={`appearance-none border rounded w-full py-2 px-3 ml-2 text-gray-700 leading-tight focus:outline-none hover:shadow-outline ${errors.data?.displayName ? "border-red-500" : ""
                          }`}
                        id=""
                        datatest-id="max_count"
                        defaultValue={2}
                        type="number"
                        min={1}
                        max={35}
                        onKeyDown={(e) => e.preventDefault()}
                        placeholder="Enter a max speaker count"
                        {...register("data.properties.diarization.speakers.maxCount", {
                          required: "Please enter a maxCount.",
                          validate: (value) => {
                            const minValue = getValues("data.properties.diarization.speakers.minCount");
                            if (value < minValue) {
                              setMaxCountError("Max speaker count must be greater than or equal to min speaker count.")
                              return false;
                            }
                            setMaxCountError("")
                            return true;
                          },
                        })}
                      />


                    </div>
                    <div>
                      {(maxCountError) && <span className="text-red-600 text-sm">
                        {maxCountError}
                      </span>}

                    </div>
                    <div>
                      {(errors.data?.properties?.diarization?.speakers?.minCount?.message || errors.data?.properties?.diarization?.speakers?.maxCount?.message) && <span className="text-red-600 text-sm">
                        Please specify the minimum and maximum speaker counts or disable diarization.
                      </span>}
                    </div>
                  </div>}
                  <div className="form-control w-full my-3">
                    <label className="label">
                      <span className="label-text">Punctuation Mode</span>
                    </label>
                    <Select
                      id="punctuation_mode"
                      options={PunctuationMode}
                      onChange={(option) => {
                        option?.value &&
                          setValue(
                            "data.properties.punctuationMode",
                            option?.value
                          );
                      }}
                    />
                  </div>
                  <div className="form-control w-full my-3">
                    <label className="label">
                      <span className="label-text">Profanity filter mode</span>
                    </label>
                    <Select
                      id="profanity_filter_mode"
                      menuPlacement="top"
                      options={ProfanityFilterMode}
                      onChange={(option) => {
                        option?.value &&
                          setValue(
                            "data.properties.profanityFilterMode",
                            option?.value
                          );
                      }}
                    />
                  </div>
                  {filteredBaseModels && (
                    <div
                      className="form-control w-full my-3"
                      style={{ zIndex: "9999" }}
                    >
                      <label className="label">
                        <span className="label-text">
                          Select Model{" "}
                          <small>(Leave blank to use the latest model)</small>
                        </span>
                      </label>
                      <Select
                        id="select_model"
                        menuPlacement="top"
                        options={filteredBaseModels}
                        onChange={(option) => {
                          option?.value &&
                            setValue("data.model.self", option?.value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="my-4">
              <Alert type="alert-error" message="All audio files and associated transcriptions will be removed after 30 days." />
            </div>
            <div className="flex items-center justify-center">
              <button
                className="bg-custom-primary btn-wide hover:bg-custom-primary text-white font-semibold py-2 px-4 rounded focus:outline-none hover:drop-shadow-lg"
                type="submit"
                datatest-id="submit_transcription"
              >
                Submit for Transcription
              </button>
            </div>

          </form>
        </div>
      </div>
    </>
  );
};
export default CreateTranscription;

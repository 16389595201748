import griffithLogo from "../assets/GRIFF1_STD_RGB_reverse.png"

const Footer = () => {
    return (
        <footer className="footer items-center bg-custom-secondary text-neutral-content">
            <div className="w-screen px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 py-3  bg-custom-secondary text-white text-xl">
                <div className="container max-w-[1400px]">
                    <div className="grid grid-cols-1 sm:grid-cols-2">
                        <div>
                            <a
                                href="https://www.griffith.edu.au"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img alt="Griffith Logo" src={griffithLogo} height={150} width={150} />
                            </a>

                        </div>
                        <div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end text-sm sticky bottom-0">
                            <a className="hover:underline" href="https://cricos.education.gov.au/Institution/InstitutionDetails.aspx?ProviderCode=00233E" target="_blank" rel="noreferrer">
                                CRICOS Provider - 00233E
                            </a><span className="mx-2">|</span>
                            <a className="hover:underline" href="https://www.teqsa.gov.au/national-register/provider/griffith-university" target="_blank" rel="noreferrer">TEQSA - PRV12076</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>)
}

export default Footer;
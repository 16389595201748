import React from 'react';

interface AlertProps {
  type: string;
  message: string;
}

const Alert: React.FC<AlertProps> = ({ type, message }) => (
  <div className="my-4 flex justify-center">
    <div className={`alert ${type} shadow-lg flex items-center justify-center`}>
      <div>
        <span>{message}</span>
      </div>
    </div>
  </div>
);

export default Alert;

// Layout.tsx

import { ReactNode, useMemo, useState } from "react";
import Menu from "./Menu";
import { TranscriptionContext } from "../contexts/TranscriptionContext";
import { Transcriptions } from "../models/GetTranscription";
import { getAccountSas } from "../services/Sas";
import { AnonymousCredential, BlobServiceClient } from "@azure/storage-blob";
import { useAuth } from "react-oidc-context";
import { AudioUrlsContext } from "../contexts/AudioUrlsContext";
import { LayoutContext } from "../contexts/LayoutContext";
import { getTranscriptionList } from "../services/Transcription";
import { firstValueFrom } from "rxjs";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const [transcriptions, setTranscriptions] = useState<Transcriptions | null>(
    null
  );
  const [downloadOption, setDownloadOption] = useState<string>("");
  const [audioUrls, setAudioUrls] = useState<
    { label: string | undefined; value: string }[]
  >([]);
  const auth = useAuth();

  const loadTranscriptionList = () => {
    const getTranscription$ = getTranscriptionList();
    const sub = getTranscription$.subscribe({
      next: (res) => {
        setTranscriptions(res);
      },
    });
    return () => sub.unsubscribe();
  };
  const getContainerClient = async (sasToken: string) => {
    const blobServiceClient = new BlobServiceClient(
      `https://${(window as any)?.__RUNTIME_CONFIG__?.azure_storage_account_name}.blob.core.windows.net/?${sasToken}`,
      new AnonymousCredential()
    );

    const containerClient = blobServiceClient.getContainerClient("audio");

    return containerClient;
  };

  const listAudioFiles = async (sasToken: string) => {
    const containerClient = await getContainerClient(sasToken);

    // Set the prefix of the blobs you want to retrieve
    const prefix = `${auth.user?.profile.preferred_username}/`;

    // Get an iterator that lists all the blobs in the container with the specified prefix
    const iterator = containerClient.listBlobsFlat({ prefix });
    const audioUrls = [];

    // Iterate over the blobs and add the URLs of the audio files to the array
    for await (const blob of iterator) {
      const blobUrl = `https://${(window as any)?.__RUNTIME_CONFIG__?.azure_storage_account_name}.blob.core.windows.net/audio/${blob.name}`;
      audioUrls.push(blobUrl);
    }

    return audioUrls;
  };

  const loadAudioUrls = async () => {
    try {
      const res = await firstValueFrom(getAccountSas());
      const data = res?.response as any;
      const urls = await listAudioFiles(data.sas);
      const options: { label: string | undefined; value: string }[] = [];
      urls.forEach((url) => {
        options.push({ label: url.split("/").pop(), value: url });
      });
      setAudioUrls(options);
    } catch (err) {
      // Handle the error
    }
  };

  const handleRefreshClick = (location: any) => {
    if (location.pathname === "/list-transcription") {
      loadTranscriptionList();
    }
    if (location.pathname === "/create-transcription") {
      loadAudioUrls().catch((e) => {
        // Handle the error
        console.error(e)
      });
    }
  };

  const processAndCreateFile = (option: string) => {
    setDownloadOption(option)
    return () => setDownloadOption("")
  };

  const layoutContextValue = useMemo(() => {
    return { downloadOption, setDownloadOption };
  }, [downloadOption, setDownloadOption]);

  return (
    <>
      <TranscriptionContext.Provider value={transcriptions}>
        <AudioUrlsContext.Provider value={audioUrls}>
          
          <Menu
            onRefreshClicked={handleRefreshClick}
            processAndCreateFile={(option) => processAndCreateFile(option)}
          />
          <LayoutContext.Provider value={layoutContextValue}>
            {children}
          </LayoutContext.Provider>
        </AudioUrlsContext.Provider>
      </TranscriptionContext.Provider>
    </>
  );
};

export default Layout;
